import { Tooltip } from "@mui/material";
import { Typography } from "@mui/material";
import React from "react";
import { formatMoney } from "../../../../Utils/MoneyUtils";
import { insightsMetricName, transFormMetricName } from "../../../../Utils/Recon/Recon360/Constants";
import "./CircleReconInsights.scss";

const CircleReconInsights = (props: any) => {
  let formattedNum;
  if (props.metric === insightsMetricName.MATCH_PERCENTAGE && props.number) {
    formattedNum = props.number.toFixed(2) + " % ";
  } else if (props.metric === insightsMetricName.NUM_RECONCILIATION_DONE && props.number) {
    formattedNum = Math.round(props.number);
  } else {
    formattedNum = props.number
      ? formatMoney({
          amount: Math.round(props.number),
          precision: 0,
          currency: "INR",
        })
      : props.metric === insightsMetricName.MATCH_PERCENTAGE
      ? "N/A"
      : "₹0";
  }

  return (
    <div className="circleReconInsights">
      <Tooltip title={<Typography style={{ fontSize: 14 }}>{props.hoverDescription}</Typography>} arrow={true}>
        <div className="circle">
          <div className="fs_24">{formattedNum}</div>
        </div>
      </Tooltip>
      <div className="mt_10" style={{ height: "30px" }}>
        <p className="fw_900">{transFormMetricName(props.metric)}</p>
      </div>
      <div className="mt_10" style={{ height: "60px" }}>
        <small className="fs_14">{props.description}</small>
      </div>
    </div>
  );
};

export default CircleReconInsights;

import { Card, CardContent, Grid } from "@mui/material";
import React from "react";
import CircleReconInsights from "../CircleReconInsights/CircleReconInsights";

const ReconInsightsSection = (props: any) => {
  return (
    <>
      <Grid container={true} spacing={2} className="center_align">
        {props.rowsDataOverview?.map((circle: any, circleInd: number) => (
          <Grid item={true} xs={12} sm={6} md={3} className="center_align_ver_horiz text_center" key={circleInd}>
            <Card
              className="w_100_per cursor_pointer"
              onClick={() => {
                props.onClickCircle(circle.metric);
              }}
            >
              <CardContent>
                <Grid>
                  <CircleReconInsights
                    metric={circle.metric}
                    number={circle.number}
                    description={circle.description}
                    hoverDescription={circle.hoverDescription}
                  />
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default ReconInsightsSection;

import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import React, { useState } from "react";

const ARReconInsightsFilter = (props: any) => {
  const [partnerName, setPartnerName] = useState<string>("");
  const [selectedCategory, setSelectedCategory] = useState<string[]>([]);

  const handleClose = () => {
    setSelectedCategory([]);
    // setPartnerName(null);
    props.setOpenPartnerViewFilter(false);
  };

  const filterPartnerLevel = () => {
    let tempRowData = props.storeRowsDataPartnerView;

    if (partnerName?.trim()) {
      const partnerNameWithoutMultiSpace = partnerName.replace(/\s+/g, " ");
      const partnerNameStr = partnerNameWithoutMultiSpace.toLowerCase();
      props.setIsFilterAppliedPartnerViewFilter(true);
      tempRowData = tempRowData?.filter((item: any) => {
        return item.businessPartnerName?.replace(/\s+/g, " ").toLowerCase()?.includes(partnerNameStr?.trim());
      });
    }

    if (selectedCategory.length > 0) {
      props.setIsFilterAppliedPartnerViewFilter(true);
      tempRowData = tempRowData?.filter((item: any) => {
        return selectedCategory?.some((val) => item.category?.includes(val));
      });
    }
    props.setRowsDataPartnerView(tempRowData);
    handleClose();
  };

  const clearAllAppliedFilters = () => {
    handleClose();
    setTimeout(() => {
      props.setOpenPartnerViewFilter(true);
      props.setRowsDataPartnerView(props.storeRowsDataPartnerView);
      props.setIsFilterAppliedPartnerViewFilter(false);
    }, 700);
  };

  return (
    <div>
      <Dialog open={props.openPartnerViewFilter} aria-labelledby="LedgerRequestFilter" className="ledgerRequestFilter">
        <DialogTitle className="dialog_header">
          <p className="fs_24">Apply Filter</p>
          <button className="delete ml_20" aria-label="close" onClick={() => handleClose()} />
        </DialogTitle>
        <DialogContent>
          <div>
            <div className="d_flex m_15">
              <div className="w_300 vertical_center_align fw_600">Partner Name:</div>
              <div className="w_400 d_flex category_textFeild_width_390 ">
                <Autocomplete
                  freeSolo={true}
                  id="free-solo-2-demo"
                  size="small"
                  disableClearable={false}
                  options={props.storeRowsDataPartnerView?.map((option) => option?.businessPartnerName)}
                  value={partnerName}
                  onChange={(_, value) => {
                    if (typeof value === "string") {
                      // Check if the value is a string before setting it
                      setPartnerName(value);
                    } else setPartnerName("");
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Enter Name"
                      InputProps={{
                        ...params.InputProps,
                        type: "text",
                      }}
                    />
                  )}
                />
              </div>
            </div>
            {false && (
              <div className="d_flex m_15 ">
                <div className="w_300 vertical_center_align fw_600">Category :</div>
                <div className="w_400 category_textFeild_width_390">
                  <Autocomplete
                    // limitTags={3}
                    value={selectedCategory}
                    fullWidth={true}
                    onChange={(_: any, value: any) => {
                      setSelectedCategory(value);
                    }}
                    size="small"
                    multiple={true}
                    id="tags-outlined"
                    options={props.allCategories}
                    getOptionLabel={(option: any) => option}
                    // defaultValue={[top100Films[13]]}
                    filterSelectedOptions={true}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select category"
                        // placeholder="Favorites"
                      />
                    )}
                  />
                </div>
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions className="dialog_footer space_between">
          <div>
            <Button
              className="theme_outline_btn"
              onClick={() => {
                clearAllAppliedFilters();
              }}
            >
              Clear All
            </Button>
          </div>
          <div>
            <Button
              className="theme_btn"
              onClick={() => {
                filterPartnerLevel();
              }}
            >
              Apply
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ARReconInsightsFilter;

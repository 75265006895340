import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import React from "react";
import LoadingIcon from "src/Components/Common/LoadingIcon";

export default function DeleteTaskAttachmentModal({
  open,
  setOpen,
  deleteCustomAttachment,
  isDeletingAttachment,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  deleteCustomAttachment: () => void;
  isDeletingAttachment: boolean;
}) {
  return (
    <Dialog open={open} sx={{ "& .MuiDialog-paper": { borderRadius: "4px", minWidth: "600px" } }}>
      <DialogTitle>Are you sure you want to Delete this document? </DialogTitle>
      <DialogActions sx={{ padding: 1 }}>
        <Button
          variant="text"
          onClick={() => {
            setOpen(false);
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={isDeletingAttachment}
          sx={{ borderRadius: "4px !important" }}
          color="error"
          variant="contained"
          onClick={() => {
            deleteCustomAttachment();
          }}
        >
          {isDeletingAttachment && <LoadingIcon loading={true} />}
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}
